const state = {
  projectStore:
    [
      {
        id: 1,
        title_en: "My Curriculum VITAE",
        title_ua: "Моє Резюме",
        title_es: "Mi Currículum VITAE",
        start_date_en: "March 2022",
        start_date_ua: "Березень 2022",
        start_date_es: "Marzo de 2022",
        end_date_en: "April 2022",
        end_date_ua: "Квітень 2022",
        end_date_es: "Abril de 2022",
        description_en: "My Curriculum VITAE (Landing page). Website is adaptive.",
        description_ua: "Моє резюме (Landing page). Сайт адаптивний.",
        description_es: "Mi Curriculum VITAE (Página de destino). El sitio web es adaptable.",
        type_en: "Landing page",
        type_ua: "Цільова сторінка",
        type_es: "Página de destino",
        techno: "HTML5, CSS3, Grid CSS, FlexBox CSS",
        sourcelink: "https://github.com/Zorger27/zorger27.github.io",
        demolink: "https://zorger27.github.io",
        youtubelink: ""
      },
      {
        id: 2,
        title_en: "Calculator",
        title_ua: "Калькулятор",
        title_es: "Calculadora",
        start_date_en: "April 2022",
        start_date_ua: "Квітень 2022",
        start_date_es: "Abril de 2022",
        end_date_en: "April 2022",
        end_date_ua: "Квітень 2022",
        end_date_es: "Abril de 2022",
        description_en: "My Calculator 2022 (Web application). Simple but functional calculator.",
        description_ua: "Мій калькулятор 2022 (веб-додаток). Простий, але функціональний калькулятор.",
        description_es: "Mi Calculadora 2022 (aplicación web). Calculadora sencilla pero funcional.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, JavaScript",
        sourcelink: "https://github.com/Zorger27/calculator",
        demolink: "https://zorin.expert/extra/calculator",
        youtubelink: ""
      },
      {
        id: 3,
        title_en: "Spinning cube",
        title_ua: "Куб, що обертається",
        title_es: "El cubo giratorio",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Spinning cube 3D made with CSS only.",
        description_ua: "Куб 3D, що обертається, створений тільки з допомогою CSS.",
        description_es: "Cubo giratorio 3D hecho únicamente con CSS.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS",
        sourcelink: "https://github.com/Zorger27/Spinning-cube",
        demolink: "https://zorin.expert/extra/graphics/cube",
        youtubelink: ""
      },
      {
        id: 4,
        title_en: "Scroll Snap features",
        title_ua: "Особливості Scroll Snap",
        title_es: "Características de Scroll Snap",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Scroll Snap feature by example of images list.",
        description_ua: "Функція Scroll Snap на прикладі списку зображень.",
        description_es: "Función de desplazamiento de ajuste mediante el ejemplo de la lista de imágenes.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS",
        sourcelink: "https://github.com/Zorger27/Scroll-Snap",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 5,
        title_en: "Contact Form",
        title_ua: "Контактна форма",
        title_es: "Formulario de contacto",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Contact Form Made with JavaScript.",
        description_ua: "Контактна форма створена з допомогою JavaScript.",
        description_es: "Formulario de contacto realizado con JavaScript.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, JavaScript",
        sourcelink: "https://github.com/Zorger27/Contact-Form",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 6,
        title_en: "Hamburger Menu",
        title_ua: "Гамбургер меню",
        title_es: "Menú Hamburguesa",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Hamburger Menu Animation.",
        description_ua: "Анімація гамбургерного меню.",
        description_es: "Animación del menú de hamburguesas.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, JavaScript",
        sourcelink: "https://github.com/Zorger27/Hamburger-menu",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 7,
        title_en: "To-Do list",
        title_ua: "Список справ",
        title_es: "Lista de tareas",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Convenient and functional To-Do list.",
        description_ua: "Зручний і функціональний список справ.",
        description_es: "Lista de tareas pendientes conveniente y funcional.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/To-Do-list",
        demolink: "https://zorin.expert/extra/todo",
        youtubelink: ""
      },
      {
        id: 8,
        title_en: "Memory game",
        title_ua: "Гра \"Пам'ять\"",
        title_es: "Juego de Memoria",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Rules of the game! The user opens any two cards. If they show the same drawings, they are \"fixed\" on the screen and no longer participate in the game. If the cards are different, then they are returned to their original position \"shirt\" up. And so it goes until all the cards are open.",
        description_ua: "Правила гри! Користувач відкриває дві будь-які картки. Якщо на них зображені однакові малюнки, то вони закріплюються на екрані і більше не беруть участі в грі. Якщо картки різні, всі вони повертаються у вихідне положення «сорочкою» догори. І так робиться доти, доки не будуть відкриті всі картки.",
        description_es: "¡Reglas del juego! El usuario abre dos tarjetas cualesquiera. Si muestran los mismos dibujos, se \"fijan\" en la pantalla y ya no participan en el juego. Si las cartas son diferentes, entonces se devuelven a su posición original \"camiseta\" levantada. Y así hasta que todas las cartas estén abiertas.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/Memory-game",
        demolink: "https://zorin.expert/extra/games/memory",
        youtubelink: ""
      },
      {
        id: 9,
        title_en: "Typical JavaScript interview tasks",
        title_ua: "Типові завдання на співбесіді з JavaScript",
        title_es: "Tareas típicas de entrevistas en JavaScript",
        start_date_en: "June 2022",
        start_date_ua: "Червень 2022",
        start_date_es: "Junio de 2022",
        end_date_en: "June 2022",
        end_date_ua: "Червень 2022",
        end_date_es: "Junio de 2022",
        description_en: "Solving typical JavaScript job interview tasks: Palindrome, FizzBuzz, Anagram, Find Vowels, Fibonacci.",
        description_ua: "Вирішення типових завдань співбесіди з JavaScript: Palindrome, FizzBuzz, Anagram, Find Vowels, Fibonacci.",
        description_es: "Resolver tareas comunes de entrevistas de trabajo por JavaScript: Palíndromo, FizzBuzz, Anagrama, Buscar vocales, Fibonacci.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/Typical-JS-tasks",
        demolink: "https://zorin.expert/extra/tasks",
        youtubelink: ""
      },
      {
        id: 10,
        title_en: "Website template for a blogger",
        title_ua: "Шаблон сайту для блогера",
        title_es: "Plantilla de sitio web para un blogger",
        start_date_en: "June 2022",
        start_date_ua: "Червень 2022",
        start_date_es: "Junio de 2022",
        end_date_en: "July 2022",
        end_date_ua: "Липень 2022",
        end_date_es: "Julio de 2022",
        description_en: "Website template for a blogger.",
        description_ua: "Шаблон сайту для блогера.",
        description_es: "Plantilla de sitio web para un blogger.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/Blog-site",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 11,
        title_en: "My favorite buttons",
        title_ua: "Мої улюблені кнопки",
        title_es: "Mis botones favoritos",
        start_date_en: "August 2022",
        start_date_ua: "Серпень 2022",
        start_date_es: "Agosto de 2022",
        end_date_en: "August 2022",
        end_date_ua: "Серпень 2022",
        end_date_es: "Agosto de 2022",
        description_en: "Nicely designed buttons (my favorites).",
        description_ua: "Красиво оформлені кнопки (мої улюблені).",
        description_es: "Botones muy bien diseñados (mi favorito).",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS",
        sourcelink: "https://github.com/Zorger27/Fav-buttons",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 12,
        title_en: "Dropdown Menu",
        title_ua: "Випадаюче меню",
        title_es: "Menú desplegable",
        start_date_en: "August 2022",
        start_date_ua: "Серпень 2022",
        start_date_es: "Agosto de 2022",
        end_date_en: "August 2022",
        end_date_ua: "Серпень 2022",
        end_date_es: "Agosto de 2022",
        description_en: "Very simple (only CSS) but wonderful working Dropdown Menu.",
        description_ua: "Дуже просте (тільки CSS), але чудово працююче Dropdown меню.",
        description_es: "Menú desplegable muy simple (solo CSS) pero que funciona de maravilla.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3",
        sourcelink: "https://github.com/Zorger27/Dropdown-Menu",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 13,
        title_en: "Sidebar Menu",
        title_ua: "Меню на бічній панелі",
        title_es: "Menú de la barra lateral",
        start_date_en: "August 2022",
        start_date_ua: "Серпень 2022",
        start_date_es: "Agosto de 2022",
        end_date_en: "August 2022",
        end_date_ua: "Серпень 2022",
        end_date_es: "Agosto de 2022",
        description_en: "Very convenient Responsive Sidebar Menu.",
        description_ua: "Дуже зручне адаптивне бічне меню.",
        description_es: "Menú de barra lateral responsivo muy conveniente.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, Boxicons CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/Sidebar-Menu",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 14,
        title_en: "My Portfolio",
        title_ua: "Моє Портфоліо",
        title_es: "Mi Portafolio",
        start_date_en: "February 2023",
        start_date_ua: "Лютий 2023",
        start_date_es: "Febrero de 2023",
        end_date_en: "June 2023",
        end_date_ua: "Червень 2023",
        end_date_es: "Junio de 2023",
        description_en: "This project is a significantly expanded CV with all diplomas and certificates, a detailed description of the experience gained in previous jobs, etc. Examples of finished programming work can be viewed on the Extra page: Calculator, To-do list, Notes, Weather, Exchange rates, Cryptocurrencies, Games, etc. There are a LOT of interesting things here!",
        description_ua: "Даний проект є істотно розширеним CV з усіма дипломами та сертифікатами, детальним описом досвіду, отриманого на попередніх роботах і т.д. Приклади готових робіт з програмування можна подивитися на сторінці «Екстра»: Калькулятор, Список справ, Нотатки, Погода, Курси валют, Криптовалюти, Ігри і т.д. Тут БАГАТО цікавого!",
        description_es: "Este proyecto es un CV significativamente ampliado con todos los diplomas y certificados, una descripción detallada de la experiencia adquirida en trabajos anteriores, etc. Los ejemplos del trabajo de programación terminado se pueden ver en la página Extra: Calculadora, Lista de tareas, Notas, Clima, Tipos de cambio, Criptomonedas, Juegos, etc. ¡Hay MUCHAS cosas interesantes aquí!",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, HTML5, CSS3, Grid CSS, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/CV-Vue",
        demolink: "https://Zorin.Expert",
        youtubelink: "https://youtu.be/fwBFfrhPJyM"
      },
      {
        id: 15,
        title_en: "Vue.js template with TypeScript",
        title_ua: "Шаблон Vue.js + TypeScript",
        title_es: "Plantilla Vue.js con TypeScript",
        start_date_en: "December 2023",
        start_date_ua: "Грудень 2023",
        start_date_es: "Diciembre de 2023",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This is the Starter Development Template on Vue.js + TypeScript with internationalization, routers and store. Prepared for connection to Google Analytics and Google Search Console. The template is customized and optimized for PWA (Progressive Web App) technology.",
        description_ua: "Це початковий шаблон для розробки на Vue.js + TypeScript з інтернаціоналізацією, маршрутизаторами та сховищем. Підготовлений для підключення до Google Analytics і Google Search Console. Шаблон налаштований і оптимізований для технології PWA (Progressive Web App).",
        description_es: "Esta es la plantilla de desarrollo inicial en Vue.js + TypeScript con internacionalización, enrutadores y almacenamiento. Preparado para conexión a Google Analytics y Google Search Console. La plantilla está personalizada y optimizada para la tecnología PWA (Progressive Web App).",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/Vue-TS-Template",
        demolink: "https://vue-template-ts.vercel.app",
        youtubelink: ""
      },
      {
        id: 16,
        title_en: "Rotating 3D cube №1",
        title_ua: "3D куб, що обертається №1",
        title_es: "Cubo 3D giratorio №1",
        start_date_en: "December 2023",
        start_date_ua: "Грудень 2023",
        start_date_es: "Diciembre de 2023",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This project provides the user with the opportunity to interact interactively with a 3D model. In this case with a cube. The model can be zoomed in/out, stopped rotating, scrolled in your direction, etc. All this became possible thanks to the use of Three.js technology and Three.js Orbit controls.",
        description_ua: "Цей проект надає користувачеві можливість інтерактивно взаємодіяти з 3D-моделлю. В даному випадку з кубиком. Модель можна збільшувати/зменшувати, зупиняти обертання, прокручувати у своєму напрямку тощо. Все це стало можливим завдяки використанню технології Three.js та Three.js Orbit controls.",
        description_es: "Este proyecto brinda al usuario la oportunidad de interactuar interactivamente con un modelo 3D. En este caso con un cubo. El modelo se puede acercar o alejar, dejar de girar, desplazar en su dirección, etc. Todo esto fue posible gracias al uso de la tecnología Three.js y los controles Three.js Orbit.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/Vue-Threejs-1",
        demolink: "https://vue-threejs-1.vercel.app",
        youtubelink: ""
      },
      {
        id: 17,
        title_en: "Vue.js template with JavaScript",
        title_ua: "Шаблон Vue.js + JavaScript",
        title_es: "Plantilla Vue.js con JavaScript",
        start_date_en: "December 2023",
        start_date_ua: "Грудень 2023",
        start_date_es: "Diciembre de 2023",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This is the Starter Development Template on Vue.js + JavaScript with internationalization, routers and store. Prepared for connection to Google Analytics and Google Search Console. The template is customized and optimized for PWA (Progressive Web App) technology.",
        description_ua: "Це початковий шаблон для розробки на Vue.js + JavaScript з інтернаціоналізацією, маршрутизаторами та сховищем. Підготовлений для підключення до Google Analytics і Google Search Console. Шаблон налаштований і оптимізований для технології PWA (Progressive Web App).",
        description_es: "Esta es la plantilla de desarrollo inicial en Vue.js + JavaScript con internacionalización, enrutadores y almacenamiento. Preparado para conexión a Google Analytics y Google Search Console. La plantilla está personalizada y optimizada para la tecnología PWA (Progressive Web App).",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Vue.js, VueX, HTML5, CSS3, FlexBox CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/Vue-JS-Template",
        demolink: "https://vue-template-js.vercel.app",
        youtubelink: ""
      },
      {
        id: 18,
        title_en: "Vue.js & Three.js template",
        title_ua: "Шаблон Vue.js + Three.js",
        title_es: "Plantilla Vue.js y Three.js",
        start_date_en: "January 2024",
        start_date_ua: "Січень 2024",
        start_date_es: "Enero de 2024",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This is the Starter Development Template on Vue.js + Three.js with internationalization, routers and store. Prepared for connection to Google Analytics and Google Search Console. The template is customized and optimized for PWA (Progressive Web App) technology.",
        description_ua: "Це початковий шаблон для розробки на Vue.js + Three.js з інтернаціоналізацією, маршрутизаторами та сховищем. Підготовлений для підключення до Google Analytics і Google Search Console. Шаблон налаштований і оптимізований для технології PWA (Progressive Web App).",
        description_es: "Esta es la plantilla de desarrollo inicial en Vue.js + Three.js con internacionalización, enrutadores y almacenamiento. Preparado para conexión a Google Analytics y Google Search Console. La plantilla está personalizada y optimizada para la tecnología PWA (Progressive Web App).",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/Vue-Threejs-Template",
        demolink: "https://vue-threejs-template.vercel.app",
        youtubelink: ""
      },
      {
        id: 19,
        title_en: "Rotating 3D cube №2",
        title_ua: "3D куб, що обертається №2",
        title_es: "Cubo 3D giratorio №2",
        start_date_en: "January 2024",
        start_date_ua: "Січень 2024",
        start_date_es: "Enero de 2024",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This project is a continuation of the \"Rotating 3D cube №1\" project. Added different cube models. They move in different directions, being in different orbits. Both pure colors and ready-made textures were used for coloring the cubes. Some sides of the cube have an image on them.",
        description_ua: "Цей проект є продовженням проекту \"3D куб, що обертається №1\". Додані різні моделі кубиків. Вони рухаються у різних напрямах, перебуваючи на різних орбітах. Для фарбування кубиків використовувалися як чисті кольори, так і готові текстури. На деяких сторонах кубика нанесені зображення.",
        description_es: "Este proyecto es una continuación del proyecto \"Cubo 3D giratorio №1\". Se agregaron diferentes modelos de cubos. Se mueven en diferentes direcciones, estando en diferentes órbitas. Para colorear los cubos se utilizaron tanto colores puros como texturas ya preparadas. Algunas caras del cubo tienen una imagen.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, JavaScript",
        sourcelink: "https://github.com/Zorger27/Vue-Threejs-2",
        demolink: "https://vue-threejs-2.vercel.app",
        youtubelink: ""
      },
      {
        id: 20,
        title_en: "Exchange rates",
        title_ua: "Курси валют",
        title_es: "Tipos de cambio",
        start_date_en: "March 2024",
        start_date_ua: "Березень 2024",
        start_date_es: "Marzo de 2024",
        end_date_en: "March 2024",
        end_date_ua: "Березень 2024",
        end_date_es: "Marzo de 2024",
        description_en: "This web application takes real foreign exchange rates from the National Bank of Ukraine. Sorts and displays them to the user in different views: list, table or creeping line.",
        description_ua: "Цей веб-додаток бере реальні курси іноземних валют в Національному Банку України. Сортує їх і відображає користувачу в різних видах: список, таблиця або рядок, що біжить.",
        description_es: "Esta aplicación web toma tipos de cambio reales del Banco Nacional de Ucrania. Los ordena y los muestra al usuario en diferentes vistas: lista, tabla o línea progresiva.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/Currencies",
        demolink: "https://currencies-zeta.vercel.app",
        youtubelink: ""
      },
      {
        id: 21,
        title_en: "Cryptocurrencies",
        title_ua: "Криптовалюти",
        title_es: "Criptomonedas",
        start_date_en: "March 2024",
        start_date_ua: "Березень 2024",
        start_date_es: "Marzo de 2024",
        end_date_en: "March 2024",
        end_date_ua: "Березень 2024",
        end_date_es: "Marzo de 2024",
        description_en: "This web application takes real cryptocurrency rates powered by CoinGecko. Sorts and displays them to the user in different views: list, table or creeping line.",
        description_ua: "Цей веб-додаток бере реальні курси криптовалют на базі CoinGecko. Сортує їх і відображає користувачу в різних видах: список, таблиця або рядок, що біжить.",
        description_es: "Esta aplicación web toma tasas de criptomonedas reales impulsadas por CoinGecko. Los ordena y los muestra al usuario en diferentes vistas: lista, tabla o línea progresiva.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/Cryptocurrencies",
        demolink: "https://cryptocurrencies-gold.vercel.app",
        youtubelink: ""
      },
      {
        id: 22,
        title_en: "Weather forecast",
        title_ua: "Прогноз погоди",
        title_es: "El Tiempo",
        start_date_en: "March 2024",
        start_date_ua: "Березень 2024",
        start_date_es: "Marzo de 2024",
        end_date_en: "April 2024",
        end_date_ua: "Квітень 2024",
        end_date_es: "Abril de 2024",
        description_en: "Custom-built weather forecasting application that delivers real-time data from OpenWeather for any city worldwide. Whether you're a tech enthusiast or just curious about the weather, this app has something for everyone! Key features of application: flexible city selection and multiple display formats.",
        description_ua: "Унікальний веб-додаток для прогнозу погоди, який використовує актуальні дані в реальному часі від OpenWeather для будь-якого міста світу. Незалежно від того, чи ви захоплюєтеся технологіями, чи просто цікавитеся погодою, цей додаток має щось цікаве для кожного! Ключові особливості програми: гнучкий вибір міста та різні формати відображення.",
        description_es: "Una aplicación web personalizada para pronósticos meteorológicos, que proporciona datos en tiempo real de OpenWeather para cualquier ciudad del mundo. Ya sea que seas un entusiasta de la tecnología o simplemente tengas curiosidad por el clima, ¡esta app tiene algo para todos! Características clave de la aplicación: selección flexible de ciudades y múltiples formatos de visualización.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/Weather",
        demolink: "https://weather-zorger.vercel.app",
        youtubelink: "https://youtu.be/cg_zJ77DHhI"
      },
      {
        id: 23,
        title_en: "3D Configurators",
        title_ua: "3D-конфігуратори",
        title_es: "Configuradores 3D",
        start_date_en: "September 2024",
        start_date_ua: "Вересень 2024",
        start_date_es: "Septiembre de 2024",
        end_date_en: "September 2024",
        end_date_ua: "Вересень 2024",
        end_date_es: "Septiembre de 2024",
        description_en: "A 3D configurator is an innovative online technology that enables users to interact with 3D product models in real-time. It’s a powerful tool for businesses that allows your customers to customize products to their preferences — changing colors, textures, and other parameters and seeing the results instantly.",
        description_ua: "3D конфігуратор — це інноваційна онлайн-технологія, яка дозволяє користувачам взаємодіяти з 3D-моделями продуктів у реальному часі. Це потужний інструмент для бізнесу, який дає вашим клієнтам змогу налаштовувати продукт відповідно до своїх вподобань — змінювати колір, текстуру та інші параметри й одразу бачити результат.",
        description_es: "Un configurador 3D es una tecnología en línea innovadora que permite a los usuarios interactuar con modelos de productos en 3D en tiempo real. Es una herramienta poderosa para empresas que permite a los clientes personalizar productos según sus preferencias: cambiar colores, texturas y otros parámetros, y ver los resultados al instante.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, HTML5, CSS3, FlexBox CSS, TypeScript, JavaScript",
        sourcelink: "https://github.com/Zorger27/3dConfigurator",
        demolink: "https://3d-configurator-max.vercel.app",
        youtubelink: "https://youtu.be/uUofz_KBYuU"
      },
    ]
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions
};